var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2a06a93e483f81cf424e0da154adc05695a7e786"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

import { env } from 'src/env/client.mjs'
import { DEVELOPMENT_ENVIRONMENTS } from 'src/types/stages'

if (!DEVELOPMENT_ENVIRONMENTS.includes(env.NEXT_PUBLIC_APP_STAGE)) {
  Sentry.init({
    dsn: 'https://10d018d5dde245369a097de05acd4af9@o1279619.ingest.sentry.io/6633554',
    environment: env.NEXT_PUBLIC_APP_STAGE,
    integrations: [
      new Sentry.BrowserTracing(),
      // needs to be set to false otherwise sentry hijacks your console.logs
      new Sentry.Integrations.Breadcrumbs({ console: false })
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  })
}
